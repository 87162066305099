@import '@popsure/dirty-swan/dist/colors';
@import '@popsure/dirty-swan/dist/grid';

.mobileCenter {
  @include p-size-mobile {
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}

.separator {
  margin-top: 4px;
  height: 4px;
  width: 0;
  background-color: $ds-purple-500;

  &GreyUnderline {
    background-color: $ds-grey-500;
  }
}
