@import '@popsure/dirty-swan/dist/colors';

.question-container {
  border-bottom: 1px solid $ds-grey-200;
  padding-bottom: 24px;
}

.question-header-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.chevron-animation {
  transition: 0.3s ease-in-out;
  transform: rotate(180deg);
}
